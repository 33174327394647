body{
  margin: 0;
  font-family:"Roboto";
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
pre{
  font-family:"Roboto" !important;
  font-size: 14px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  color: inherit !important;
}
a.d-inline-flex.active{
  color: #007bff !important;
}
