.appBar {
    background-color: #fffbfe !important;
}

.searchIcon {
    border-radius: 0px 20px 20px 0px !important;
}

.menuArrowDiv {
    text-align: right;
    height: 7px;
    position: absolute;
}

.menuArrow {
    margin-top: -14px;
    margin-right: 10px;
}

.dateFont {
    font-size: 40px;
    margin-right: 10px !important;
}

.cartFont {
    font-size: 12px;
}

/*overwriting*/
.MuiIconButton-edgeEnd {
    margin-right: -14px !important;
}

.MuiIcon-fontSizeSmall {
    color: black !important;
}

/*for menu*/
.MuiList-padding 
{
    padding: 0px !important;
}
/* .accountMenu{
    display:none;
} */
.accountMenu .MuiPopover-paper {
    margin-top: 40px;
    margin-left: -39px;
}
/* .icon:{
    color:red
}
.icon:hover{
  .accountMenu{
    display: block;
  }
} */

.cartMenu .MuiPopover-paper {
    margin-top: 74px;
    margin-left: -120px;
}

.cartMenu .MuiListItem-root {
    width: 330px;
    display: block;
}

.text-color{
    color: black !important;
}
.text-black{
    color:black !important
}
.hoverClass{
display:none;
}
.showhidelogintile:hover .hoverClass{
    display:block;
    background-color: white;
    color:black;
    border-radius: 5px;
    z-index: 9999999;
    position: absolute;
    top:70px;
}
header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionSticky.MuiAppBar-colorPrimary.MuiPaper-elevation4 {
    box-shadow: 0px 1px 1px -1px #b0aeae;
    border-top: 0px !important;
}   
