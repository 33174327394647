.corporateSteps {
  background-color: #f2f2f2;
  padding-top: 50px;
  padding-bottom: 50px;
}

.partnerButton {
  border: 2px orange solid !important;
  color: orange !important;
  background-color: transparent !important;
}

.partnerButton:hover {
  background-color: #FFA500 !important;
  color: #FFFFFF !important;
}

.contactDiv {
  background-color: #000000;
  color: #FFFFFF;
}

.sendButton {
  width: 200px;
  height: 40px;
}
