@import "../../../assets/styles/constants.scss";
.timeFont {
  font-size: 30px;
}

.productsNav {
  background-color: $colorGrey !important;
  position: relative !important;
  color: #FFFFFF !important;
  max-height: 50px !important;
  z-index: 10 !important;
  .cNav{
    display: flex;
    align-items: center;
    height: 50px;
    background-color: $colorGrey;
    position: absolute;
    top: 0px;
    .hoveringMenuDiv{
      cursor: pointer;
      padding: 0px;
    }
    .hoveringMenuDiv:hover {
      .hoveringMenu {
        display: block;
      }
    }
    .navP,.pp{
      height: 100%;
      // position: relative;
    }
    .hoveringMenu {
      position: absolute;
      display: none;
      top: 50px;
      li.nav-item{
        padding: 8px 12px;
      }
    }
    .hoveringMenu.right0{
      right: 0px;
    }
    .itemP{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 15px;
      border-right: 1px solid #444;
      .item{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .val{
          position: absolute;
          bottom: 0px;
          font-size: 12px;
        }
      }
    }
    .itemP:hover{
      background-color: #222;
    }
  }
  .options {
    background-color: #515151 !important;
    color: #FFFFFF !important;
    padding: 10px;
    padding-top: 5px;
    min-width: 300px;
  }
  .optionsSub{
    min-width: 170px;
  }
}

.optionsRight {
  margin-left: -280px;

  li {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.calendar {
  border: 8px solid #666 !important;
  color: #666;
}


.MuiGrid-root.card-padding.MuiGrid-item.MuiGrid-grid-xs-3 {
  padding:8px 22px !important;
}

#hide-onScrollY{
  position: inherit;
  top: 0px;
  transition-delay: 0.5s;
  transition: top 0.5s;
  background-color: white;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.infinite-scroll-component::-webkit-scrollbar {
  display: none;
}
 
/* Hide scrollbar for IE, Edge and Firefox */
.infinite-scroll-component {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

