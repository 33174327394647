@import "../../assets/styles/constants.scss";

@keyframes border-shadow{
  0% {box-shadow: 0 0 5px 1px #c1c1c1fc;}
  25% {box-shadow: 0 0 2px 1px #c1c1c1fc;}
  50% {box-shadow: 0 0 1px 1px #c1c1c1fc;}
  75% {box-shadow: 0 0 2px 1px #c1c1c1fc;}
  100% {box-shadow: 0 0 5px 1px #c1c1c1fc;}
}




.productDetails {
  .sideImages {
    display: flex;
    flex-direction: column;
    height: auto;

    img {
      border-color: white;
      border-width: 3px;
      border-style: solid;
      height: 80px;
    }

    img:hover, img.active {
      border-color: $colorPrimary;
    }
  }

  .imgLive {
    border-color: white;
    border-width: 3px;
    border-style: solid;
  }

  .price {
    width: 135px;
    height: 90px;

    h1, h5 {
      font-weight: bold;
    }

    h1 {
      line-height: 0.8;
      font-size: 50px;
    }

    span {
      font-size: 12px;
      float: right;
    }

    span:last-child {
      color: $linkColor;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .upgrade {
    margin: 8px 0px;

    ._item {
      cursor: pointer;
      margin-right: 30px;
    }
  }

  .selectP {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .select.error {
      box-shadow: 0px 0px 5px 1px $colorRed;
      border: 1px solid $colorRed;
    }

    .select {
      position: relative;
      width: 100%;
      //height: 50px;
      box-shadow: 0px 0px 5px 1px #c1c1c1fc;
      border-radius: 3px;
      padding: 18.5px 15px !important;
      //display: flex;
      //justify-content: flex-start;
      align-items: center;
      border: 1px solid #a5a5a585;
      cursor: pointer;
      //animation-name: border-shadow;
      //animation-iteration-count: infinite;
      //animation-timing-function: ease-in-out;
      //animation-duration: 2s;
      //animation-direction: alternate;
      //animation-fill-mode: both;

      label {
        margin: 0px;
        cursor: pointer;
      }

      .list-group {
        position: absolute;
        top: 50px;
        width: 100%;
        left: 0;
        max-height: 200px;
        overflow: auto;
      }

      .list-group.hidden {
        display: none;
      }

      .list-group.open {
        display: block;
      }
    }
  }

  .buttonG {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      width: 48%;
      height: 50px;
      font-size: 22px;
      font-weight: 800;
      color: #fff;
      text-transform: uppercase;
    }
  }

  .description {
    .item {
      margin-top: 15px;

      h5 {
        font-weight: 500;
      }
    }
  }

  .dateSelect {
    .date {
      font-size: 30px;
    }

    .do {
      justify-content: space-evenly;
      padding-left: 5px;
      font-size: 12px;
    }
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.font-red {
  color: $colorRed;
}

.space-between {
  justify-content: space-between;
}

.myModal {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  box-shadow: 0px 0px 4px #929292;
  border-radius: 8px;

  .modal-dialog {
    width: 100%;
    min-height: 300px;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    margin: 1px;

    .modal-content {
      background-color: white;
      height: 100%;

      .modal-body {
        padding: 0px;
        min-height: 350px;
      }

      .modal-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
      }

      .modal-header .close {
        margin: 0px;
      }

      .back-arrow {
        color: $colorLightPurple;
        padding-left: 20px;
        cursor: pointer;
      }

      .back-arrow, .modal-title {
        font-size: 25px;
        font-weight: bold;
      }

      .backtc {
        cursor: pointer;
        color: $colorLightPurple;
        font-size: 18px;
        position: absolute;
        width: 100%;
        left: 0px;
        bottom: 0px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 13px 20px;
        background: #eaeaea;
      }

      .react-calendar {
        width: 100%;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        border: 0px !important;
        box-shadow: 0px 0px 4px grey;

        .react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
        .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
          display: none;
        }

        .react-calendar__navigation__arrow.react-calendar__navigation__prev-button, .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
          position: relative !important;
          top: 0px !important;
          flex-grow: 1;
        }

        .react-calendar__navigation__label {
          pointer-events: none;
        }

        .react-calendar__tile--now {
          background: none !important;
        }

        .react-calendar__month-view__days__day--weekend {
          color: inherit !important;
        }

        .react-calendar__navigation,
        .react-calendar__tile--active {
          background-color: $colorLightPurple !important;
          color: black !important;
        }

        .react-calendar__navigation button {
          color: #fff;
        }

        .react-calendar__navigation .react-calendar__navigation__arrow {
          font-size: 40px;
          position: relative;
          top: -5px;
        }

        .react-calendar__navigation button:hover {
          background-color: #fff3fc !important;
        }

        .react-calendar__tile--active, .react-calendar__tile:disabled, .react-calendar__tile.react-calendar__month-view__days__day {
          padding: 20px !important;
          font-size: 16px;
        }

        .react-calendar__navigation button:enabled:hover,
        .react-calendar__navigation button:enabled:focus,
        .react-calendar__navigation button[disabled] {
          background-color: transparent !important;
        }

        .react-calendar__navigation button[disabled] {
          opacity: 0.5;
        }

        .react-calendar__navigation__label {
          font-size: 20px;
        }
      }
    }

    .scroll {
      max-height: 300px;
      overflow: auto;

      .list-group {
        .list-group-item {
          border: 0px;
          width: 80%;
          margin: auto;

          .item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            border-radius: 5px;
            height: 45px;
            cursor: pointer;

            h6 {
              margin: 0px;
              font-size: 15px !important;
            }

            input[type="radio"] {
              display: none;
            }

            input[type="radio"] + span.radio {
              border: 2px solid #fff;
              box-shadow: 0 0 0 2px #555;
              width: 11px;
              height: 11px;
              cursor: pointer;
              -moz-border-radius: 50%;
              border-radius: 50%;
              display: flex;
              align-self: center;
              margin: 0px 20px 0px 10px;
            }

            input[type="radio"]:checked + span.radio {
              background-color: $colorPrimary;
              color: $linkColor !important;
              box-shadow: 0 0 0 2px $colorPrimary;
            }
          }

          .item div:first-child {
            border: 1px solid #696868;
            width: 75%;
            height: 100%;
            display: flex;
            align-items: center;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-right: 0px;

            input[type="radio"] {
              background-color: $colorPrimary;
            }
          }

          .item .checked {
            border: 1px solid $colorPrimary !important;
          }

          .item .price {
            background-color: $colorLightPurple;
            height: 100%;
            align-items: center;
            justify-content: center;
            width: 25%;
            display: flex;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            h6 {
              color: #fff;
            }
          }

          .item .time {
            border-right: 1px solid !important;
            border-radius: 5px !important;
            margin: 0 auto;
            justify-content: center;
          }
        }
      }
    }
  }
}


.add-to-cart-btn {
  background-color: #b379c5;
  border-radius: 0;
  font-weight: bolder;
  text-transform: uppercase;
  height: 50px;
  font-size: 18px;
  color:white;
}

.by-now-btn {
  border-radius: 0;
  font-weight: bolder;
  text-transform: uppercase;
  color: white;
  height: 50px;
  font-size: 18px

}
