li.manueItem{
    margin: 8px;
}
ul.manue {
    list-style-type: none;
    padding: 0;
}
.manueItem:hover {
    background-color: #ebebeb;
    cursor: pointer;
}