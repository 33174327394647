$radiusSm:4px;
$colorPrimary:#e4c4b9;
$colorPrimaryDark:#d2b2a7;
$colorRed:#db4a39;
$linkColor:#2179d0;
$colorSecondary:#FFA500;
$lightGrey:#f1f1f1;
$boxShadow:0px 1px 3px 1px #ababab;
$darkGrey:#777;
$colorGrey:#666;
$blackGrey:#222;
$colorLightPurple: #B379C5;
