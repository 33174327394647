@import "../../../assets/styles/constants.scss";
.corporateNav {
    background-color: purple !important;
    color: #FFFFFF !important;
    max-height: 46px !important;
}

.navOptions {
    background-color: $colorPrimaryDark !important;
    color: #FFFFFF !important;
    margin-top: 32px;
}

.hoveringMenu {
    position: absolute;
    display: none;
}

.hoveringMenuDiv:hover {
.hoveringMenu {display: block;}
}
