@import "./constants";

body {
  font-size: 14px;
  font-family: "Roboto";
  background-color: #F2F2F2;
}

::selection {
  color: #fff;
  background: $colorLightPurple;
}

.text-w {
  color: #fff !important;
}

.btn-success {
  background: $colorPrimary;
  border-color: $colorPrimary;
}

.btn-success:hover, .btn-success.focus, .btn-success:focus {
  background: $colorPrimaryDark;
  border-color: $colorPrimaryDark;
  box-shadow: $boxShadow
}

.MuiBackdrop-root {
  z-index: 2 !important;
}

.productB {
  min-width: 330px;
  max-width: 330px;
  margin-top: 20px;
  margin-bottom: 20px;

  img {
    min-height: 300px;
    width: 100%;
  }

  .MuiCardContent-root {
    padding: 5px;
    padding-bottom: 8px !important;
  }
}

.productB:hover {
  box-shadow: 0px 2px 8px 0px #c1c1c1;
}

.breadP {
  display: flex;
  justify-content: flex-start;

  div {
    font-size: 15px;
    margin-right: 5px;

    span {
      color: #000;
      font-size: 14px;
    }
  }
}

footer {
  background-color: red;
}

.appBar, .accountMenu, .navHeader,.cartMenu {
  z-index: 1300 !important;
  //box-shadow: none !important;
  padding-bottom: 1%;
}

.expansionH {
  div.row {
    display: flex;
    margin: 0px !important;
  }

  div.child {
    display: flex;
    align-items: center;
  }
}

.badgeE {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgPC {
  width: 100px;
  height: auto;
}

.cartMenu {
  max-height: 490px;
  overflow: auto;

  .cartIP {
    margin-top: 5px;
    .thumbP{
      height: 60px;
      width: 60px;
    }
    .cartItemNumber {
      border-radius: 0px 5px 5px 0px;
      width: 34px;
      position: absolute;
      left: -5px;
    }
    
    .MuiCardContent-root {
      padding-bottom: 10px !important;
    }
    .p-text{
      font-size: 13px;
      margin: 0px;
    }
    .p-text-icon{
      font-size: 19px;
    }
    .address{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      width: 100%;
      padding: 8px 0px;
    }
  }

  .stickyItemCart {
    position: sticky;
    bottom: 0px;
    background: #fff;
    .total{
      border-top: 1px solid #e9ebec;
    }
  }
}

.navSearch {
  position: relative;

  .list-group {
    position: absolute;
    width: 100%;
    top: 40px;
    max-height: 325px;
    overflow: auto;

    .list-group-item {
      color: #000;
      cursor: pointer;
    }

    .list-group-item.bg-grey {
      background-color: $lightGrey;
      color: $darkGrey;
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23) !important;
    border-width: 1px;
  }

  .searchIcon {
    padding: 8px;
    padding-left: 13px;
    padding-right: 13px;
  }
}

.hidden {
  display: none !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.b-grey {
  background-color: $blackGrey !important;
}

.catCard {
  height: auto;

  .MuiCardActions-root {
    height: 20%;
    justify-content: center;
  }

  img {
    max-height: 80%;
    height: auto;
    width: 100%;
  }
}

.loaderP {
  z-index: 100001 !important;
}

.navTimer {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.navHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.hCL {
  min-height: 470px;
  max-height: 490px;
  background-size: cover !important;
  background-position: center !important;
}

.col.col-centralised {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.position-relative {
  position: relative !important;
}

.right-0 {
  right: 0px !important;
}

.footerMenus:hover {
  color: #FFA500;
}

.footerMenus:active {
  color: #db4a39;
}

.popularSection {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transition: all ease-in-out 0.5s;
}

.popularSection:hover {
  margin-top: -14px;
}

.currencyS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.currencyNav {
  position: absolute;
  right: 12px;
  top: 30px;
  z-index: 11111;

  .menu-container {
    visibility: hidden;
    opacity: 0;
  }

  .menu-container.active {
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .user-menu {
    padding: 0;
    list-style: none;
  }

  .user-menu .user-menu-link {
    display: block;
    text-decoration: none;
    color: #444;
    font-size: 14px;
    padding: 10px 15px;
    text-align: left;
    border-bottom: 1px solid #dadada;
    cursor: pointer;
  }

  .user-menu .user-menu-link:hover {
    color: $colorPrimaryDark;
  }
}

.whatsApp {
  position: fixed !important;
  right: 3% !important;
  bottom: 20% !important;
  z-index: 999 !important;
  color:white !important;
  background-color: rgb(25, 118, 47) !important;
}

.addon {
  background-color: #fff !important;
  position: relative;

  .footer {
    // position: absolute;
    width: 100%;
    bottom: 0px;
  }

  .header, .footer {
    background-color: #F6F6F6;
  }

  .pTitle {
    width: 100%;
    text-overflow: ellipsis;
    padding: 0px 10px;
    margin: 0px;
  }
}
.addonCart{
  .actions{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    input{
      padding: 5px !important;
      text-align: center !important;
      width: 42%;
    }
  }
}
.addonsP{
  margin-top: 10px;
  .addon{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
    border-radius: 3px;
    background: $lightGrey !important;
    width: 100%;
    .others{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .img{
      padding-right: 5px;
      img{
        height: 50px;
        width: 50px;
        border: 1px solid #c7c5c5;
      }
    }
    .quantity{
      .item{
        margin-bottom: 3px;
      }
      .item:last-child{
        margin-bottom: 0px;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .quantityHandlerP{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        .p-text{
          margin-right: 5px;
        }
        .handler{
          background: #fff;
          border: 1px solid #dadada;
          height: 27px;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          font-size: 15px;
          span{
            padding: 0px 10px;
          }
          .btn{
            flex-grow: 1;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fdfdfd;
          }
          .btn:first-child{
            border-right: 1px solid #dadada;
          }
          .btn:last-child{
            border-left: 1px solid #dadada;
          }
        }
      }
    }
    .delete{

      height: 20px;
      width: 20px;
      .p-text-icon{
        color: #747474;
      }
      cursor: pointer;
    }
  }
  .addonLoading{
    width: 50%;
    height: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.addonsP.checkout{
  border-radius: 4px;
  display: flex;
  .addon{
    padding:15px;
    background: #f5f5f5 !important;
    justify-content: flex-start;
    width: auto;
    overflow: auto;
    .img{
      img{
        height: 80px;
        width: 80px;
        border: 1px solid #c7c5c5;
      }
    }
    .others{
      width: 100%;
      justify-content: flex-start;
      .delete{
        display: flex;
        height: 100%;
        align-items: center;
        width: 60%;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
  .addonLoading{
    width: 50%;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.addAddressBtnP{
  background: white;
  padding: 15px 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 1px #929292;
  color: #1b7cf6;
  font-size: 17px;
  letter-spacing: 1.5px;
  cursor: pointer;
}
.addAddressBtnP:hover{
  box-shadow: 0.5px 1px 2px 1px #929292;
}
.addressBox{
  display: flex;
  cursor: pointer;
  .checkP{
    width: 30px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .check{
      height: 20px;
      width: 20px;
      background: no-repeat;
      display: flex;
      border-radius: 50%;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 0px;
      padding: 0px;
      border: 2px solid $colorSecondary;
      span{
        height: 10px;
        width: 10px;
        background: $colorSecondary;
        border-radius: 5px;
        padding: 0px;
        margin: 0px;
        display: none;
      }
    }
  }
}
.addressBox.selected{
  .check{
    span{
      display: block;
    }
  }
 // pointer-events: none;
}
.cartMenu{
  position: relative;
  overflow: visible;
  label{
    margin: 0px;
    padding-left: 11px;
    padding-right: 16px;
    padding-bottom: 12px;
  }
}
.cartContentP{
  $height:400px;
  position: absolute;
  top: 50px;
  right: 0px;
  background: #fff;
  color: #333;
  width: 340px;
  display: none;
  
  box-shadow: $boxShadow;
  .content{
    position: relative;
    max-height: $height;
    overflow: auto;
  }
  .navCartLoader{
    min-height: $height;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}
.cartContentP:after {
  position: absolute;
  top: -20px;
  right: 20px;
  display: inline-block;
  content: "";
  border: 10px solid transparent;
  border-bottom-color: #fff;
}
.backDrop{
  position: absolute;
  z-index: 1111;
  background: red;
  height: 100%;
  width: 100%;
  top: 0px;
  overflow: hidden;
}
.myDrop{
  opacity: 0;
  display: none !important;
}
.homeMargin {
  //margin-top: 4.5rem!important;
}

.modal-dialog {
  max-width: 100% !important;
}
.home-page{
  h2{
    font-size: 14px;
  }
}
@media (max-width: 318px) {
  .addonsP.checkout{
    border-radius: 4px;
    display: flex;
    .addon{
      padding:15px;
      background: #f5f5f5 !important;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      width: auto;
      overflow: auto;
      .img{
        img{
          height: 80px;
          width: 80px;
          border: 1px solid #c7c5c5;
        }
      }
      .others{
        width: 100%;
        justify-content: flex-start;
        .delete{
          display: flex;
          height: 100%;
          align-items: center;
          width: 60%;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }
    .addonLoading{
      width: 50%;
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}

@media screen and (max-width: 480px) {
  .react-code-input {
    input{
      text-align: center;
      padding-left: 4px !important;
      font-size: 18px !important;
    }
  }
}