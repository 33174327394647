/*main style file*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');


/*over write*/
a {
    text-decoration: none !important;
}

.MuiFormControl-root {
    width: 100%;
    border-radius: 5px;
}

.MuiButtonBase-root:focus {
    outline: none !important;
}

.radioCustomized .MuiTypography-body1 {
    font-size: 14px !important;
}

.MuiExpansionPanelDetails-root {
    display: block !important;
}

.navBar .MuiTypography-body1 {
    font-size: 12px !important;
}

.customizedTable .table td {
    border-top: 0 !important;
}

/*colors*/
.color_primary {
    color: #db4a39 !important;
}

.color_secondary {
    color: #FFA500 !important;
}

.bg_primary {
    background-color: white !important;
}

.bg_secondary {
    background-color: #B379C5 !important;
}
.bg_dark_grey{
    background-color: #333 !important;
}

button.disabled{
    pointer-events: none;
    opacity: 0.5;
}

#hide-topNav{
    transition: top 0.5s;
}
#hide-secondaryNav{
    transition: top 0.5s;
    top: -100px;
}
.addonImages{
    width:221px;
    height:221px;
}
@media screen and (max-width: 475px){
    .addonImages{
        width:100%;
        height: auto;
    }
}

