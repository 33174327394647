.numbering {
    margin-left: -25px;
    border-radius: 0px 5px 5px 0px;
}

.cardSelect {
    width: 70px !important;
}

.cardInput {
    width: 84px !important;
}
