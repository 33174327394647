.numbering {
    margin-left: -25px;
    border-radius: 0px 5px 5px 0px;
}

.giftDiv {
    width: 100px;
}

.detailsFont {
    font-size: 12px;
}

.deliveryTime{
    display: flex;
    flex-direction: row;
    padding-left: 8%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #999999;
    background-color: #F8F8F8;
}

.changeDeliveryDate{
    border: 1px solid #999999;
    border-top: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B379C5;
    cursor: pointer;
    padding: 12px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #F8F8F8;
}

.selectedAddress{
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #999999;
    background-color: #F8F8F8;
    padding: 12px;
    border-bottom: 0;
}

.changeAddress{
    border: 1px solid #999999;

    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B379C5;
    cursor: pointer;
    padding: 12px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #F8F8F8;
}
