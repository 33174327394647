
.next-button {
  background-color: #f2f2f2;
  color: #000000 !important;
  width: 60px;
  height: 60px;
}

.prev-button {
  background-color: #f2f2f2;
  color: #000000 !important;
  width: 60px;
  height: 60px;
}
// .swiper-slide{
//   width:345px !important
// }

.swiper-div{
  width: 24.4% !important;
}

.card-width{
  width: 20%;
}
.stripe-height{
  height: 38px;
}

@media screen and (max-width: 1157px) {
  .stripe-height{
    height: auto;
  }

}
