@import "../../assets/styles/constants";
.navStyle {
    background-color: #fafbfd !important;
    color:#aa3c90 !important;
    padding-top:0px;
    padding-bottom:0px;
    height: 45px !important;


       .MuiAppBar-colorPrimary {
            background-color: transparent !important;
        }
}

.MuiToolbar-regular{
    min-height: 55px !important;
}

h6 {
    cursor: pointer !important;
    font-size: 12px !important;
}

.bottomBorder {
    border-bottom: 2px solid #db4a39;
}

/* overwrite*/
.categoryMenu .MuiPopover-paper {
    margin-top: 2.5%;
    margin-left: -0.7% !important;
}
.navItem{
    li{
        list-style: none;
        position: relative;
        .subItemsP{
            z-index: 2;
            display: none;
            position: absolute;
            width: auto;
            min-width: 180px;
            min-width: 200px;
            background: white;
            top: 30px;
            left:-8px;
            border-bottom-right-radius: $radiusSm;
            border-bottom-left-radius: $radiusSm;
            box-shadow: $boxShadow;
            ul{
                padding: 0px;
            }
            li{
                color:black;
                padding: 12px;
                h6{
                    margin: 0px;
                }
            }
            li:hover{
                background: $lightGrey;
                color:#aa3c90;
                border-bottom-right-radius: $radiusSm;
                border-bottom-left-radius: $radiusSm;
            }
        }
    }
    .subSecP{
        min-width: 200px;
        .head{
            color: #aa3c90;
            margin: 0px;
            padding: 15px 12px;
        }
    }
}
.navItem:hover{
    border-bottom: 4px solid #e4c4b9;
    li>.subItemsP{
        display: flex;
    }
}

.MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 4px 8px !important;

}

.login-section {
    background-color: #333333;
    ul {
        padding-left: 0;

        li {
            display: inline-block;
            width: calc(100%/3);
            text-align: center;
            border: 1px solid #d29de3;
            color: white;
            font-size: 13px;
            padding: 13.5px 5px;
        }
    }
}

ul .MuiPaper-elevation1{
    box-shadow: none !important;
}
