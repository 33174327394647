.buttonWidth {
    width: 60%;
}

.googleButton {
    background-color: #db4a39 !important;
    color: #FFFFFF !important;
}

.otpButton {
    background-color: #666666 !important;
    color: #FFFFFF !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.social-login span::before{
    content: "";
    position: absolute;
    left: 0;
    width: 45%;
    height: 1px;
    background: #bbbbbb;
    top: 50%;
}

.social-login span::after{
    content: "";
    position: absolute;
    right: 0;
    width: 45%;
    height: 1px;
    background: #bbbbbb;
    top: 50%;
}

